import React from 'react';
import { graphql } from 'gatsby';
import {
  ColorDataTableDesign,
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Tagging"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Colors" tierThree="Tagging" />

      <PageNavigation links={['Usage', 'Values']} />

      <Section title="Usage">
        <SectionSubhead>Text Color Contrast</SectionSubhead>

        <Paragraph>
          Each tagging color passes accessibility standards when used with the
          appropriate{' '}
          <Link href="/guidelines/colors/content/design">content color</Link>.
        </Paragraph>

        <List type="unordered">
          <li>
            Use <em>Base White</em> with most tagging colors: <em>Grey</em>,{' '}
            <em>Black</em>, <em>Red</em>, <em>Green</em>, <em>Blue</em> and{' '}
            <em>Purple</em>
          </li>
          <li>
            Use <em>Base Black</em> with lighter tagging colors: <em>White</em>,{' '}
            <em>Yellow</em> and <em>Orange</em>
          </li>
        </List>

        <DontDo
          dontText="use Base White on lighter tagging colors."
          doText="use Base Black on White, Yellow and Orange."
          imgFilename="colors-tagging-content"
        />

        <SectionSubhead>Font Weight</SectionSubhead>

        <Paragraph>
          Even with the appropriate text color, some tagging base colors only
          pass accessibility at a lower threshold (3:1). Because of this, stick
          to bold text on base tagging colors.
        </Paragraph>
        <Paragraph>
          Shade tagging colors are suitable for all text weights and sizes.
        </Paragraph>
        <DontDo
          dontText="use regular weighted text on base colors."
          doText="use bold text on base colors and regular weighted text on shades."
          imgFilename="colors-tagging-bold"
        />
      </Section>

      <Section title="Values">
        <ColorDataTableDesign data={data} />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query taggingColorsDesign($type: String = "/Tagging/") {
    ...colorDataDesign
  }
`;
